//================================= OPEN POPUP

const body = $('body');

let popups = $('.popup');

window.openPopup = function (popupID) {
	let popup = $('#' + popupID);

	if (popup.length === 1)
	{
		// close other open popups
		$('.popup.popup--active').each(function () {
			closePopup($(this).attr('id'));
		});

		popup.addClass('popup--active');

		body.addClass( 'popup-open' );

		// close mobile menu
		body.removeClass( 'menu-open' );
		$( '#menu-toggle' ).removeClass( 'tcon-transform' );

		// close popup when clicking outside popup content
		// (unbind event before binding to avoid firing the event when clicking on a popup opened after the first)
		body.off('click.closePopup').on('click.closePopup',function(event){
			let popupContent = $('.popup__content', popup);

			// if the target of the click isn't the container nor a descendant of the container
			if (!popupContent.is(event.target) && popupContent.has(event.target).length === 0)
			{
				//console.log(event.target);
				closePopup(popupID);
				body.off('click.closePopup');
			}
		});

		popup.trigger( 'popup-open' );
	}
};

window.closePopup = function (popupID) {
	let popup = $('#' + popupID);

	if (popup.length === 1)
	{
		popup.removeClass('popup--active');
		body.removeClass('popup-open');

		resetWpcf7Form($('.wpcf7-form', '#' + popupID));
	}
};

$( '.open-popup' ).off().on( 'click', function(e) {
	e.preventDefault();

	let popupID = $(this).data('popup');
	openPopup(popupID);

	return false;
} );

popups.on( 'click', '.popup__close, .close-popup', function(e) {
	e.preventDefault();

	let popupID = $(this).parents('.popup').attr('id');
	closePopup(popupID);

	return false;
} );

function resetWpcf7Form(form) {
	form.trigger('reset');
	form.removeClass('sent').addClass('init');
	$('.wpcf7-response-output', form).addClass('wpcf7-display-none').removeClass('wpcf7-mail-sent-ok wpcf7-validation-errors').removeAttr('style').empty();
	$('.wpcf7-not-valid-tip', form).remove();
	$('.toggle-control', form).removeClass('toggle-control--active');
	$('.wpcf7-submit', form).prop('disabled', true);
}