const _window = $(window);
const body = $('body');
import magButton from './mag-button';
import {cursorElement, customCursor} from './custom-cursor';


const attivitaMenu = document.querySelector('.attivita-menu');
const toggleAttivitaMenuItem = $('.menu-item--toggle-attivita-menu');
let magButtonsMenuAttivita = [];
let attivitaMenuTimer;
const attivitaMenuDelay = 300;



/**
 * Disable click on .menu-item--toggle-attivita-menu anchor
 */
/*$('a', toggleAttivitaMenuItem).on('click', function (e) {
	e.preventDefault();
	return false;
});*/


/**
 * Open submenu on mobile
 */
$('> a', toggleAttivitaMenuItem).on('click', function(e){
	if (matchMedia('(max-width: 991.98px)').matches)
	{
		e.preventDefault();
		e.stopPropagation();
		$(this).parents('.menu-item').toggleClass('open').children('.sub-menu').slideToggle();

		// don't change class when the user clicks on the plus/minus icon
		$('.tcon', $(this)).toggleClass('tcon-transform');
	}
});


/**
 * Init magnetic buttons inside the menu
 */
if (matchMedia('(any-hover: hover)').matches)
{
	[...document.querySelectorAll('.attivita-menu .mag-button')].forEach(
		(item, pos) => {

			const magBtn = new magButton(item);

			magBtn.on('enter', () => customCursor.enterBtn());
			magBtn.on('leave', () => customCursor.leaveBtn());

			magButtonsMenuAttivita.push(magBtn);
		}
	);
}



/**
 * Open the menu when over toggleAttivitaMenuItem
 */
if (matchMedia('(any-hover: hover)').matches)
{
	toggleAttivitaMenuItem
		.on('mouseenter', function () {

			if (matchMedia('(min-width: 992px)').matches)
			{
				openAttivitaMenu();
			}

		})
		.on('mouseleave', function () {

			clearTimeout(attivitaMenuTimer);

		});
}
else
{
	toggleAttivitaMenuItem
		.on('click', function () {

			openAttivitaMenu();

		});
}


/**
 * Close the menu when over logo and other navigation menu items
 */
$('#logo, .main-navigation__menu .menu-item:not(.menu-item--toggle-attivita-menu)')
	.on('mouseenter', function () {

		closeAttivitaMenu();

	});


/**
 * Close the menu when leaving the menu, clear timeout when entering the menu
 */
$('.attivita-menu')
	.on('mouseenter', function () {

		clearTimeout(attivitaMenuTimer);

	})
	.on('mouseleave', function () {

		closeAttivitaMenu()

	});



/**
 * Open the menu
 */
function openAttivitaMenu() {
	clearTimeout(attivitaMenuTimer);

	// after hovering for attivitaMenuDelay, open the menu
	attivitaMenuTimer = setTimeout(function () {
		body.addClass('attivita-menu-open inverted-header');

		// calculate the positions of the magnetic buttons after the menu's and buttons' animations are completed
		setTimeout(function () {
			magButtonsMenuAttivita.forEach(
				(item, pos) => {
					item.calculateSizePosition();
				}
			)

			// close .attivita-menu when clicking outside magbuttons on touch devices
			if (matchMedia('(any-hover: none)').matches)
			{
				body.off('click.closeAttivitaMenu').on('click.closeAttivitaMenu', function (event) {
					let buttons = $('.mag-button', '.attivita-menu');

					// if the target of the click isn't the container nor a descendant of the container
					if (!buttons.is(event.target) && buttons.has(event.target).length === 0)
					{
						closeAttivitaMenu();
						body.off('click.closeAttivitaMenu');
					}
				});
			}

		}, 300 + 240); // duration of .mag-button transition + delay of the last .mag-button's transition

	}, attivitaMenuDelay);
}


/**
 * Close the menu
 */
function closeAttivitaMenu() {
	clearTimeout(attivitaMenuTimer);

	// close .attivita-menu after attivitaMenuDelay
	attivitaMenuTimer = setTimeout(function () {
		body.removeClass('attivita-menu-open inverted-header');

		// calculate the positions of the magnetic buttons after the menu's animation is completed
		setTimeout(function () {
			magButtonsMenuAttivita.forEach(
				(item, pos) => {
					item.calculateSizePosition();
				}
			);
		}, 300);

	}, attivitaMenuDelay);
}


/**
 * Change the style of the cursor when inside the menu
 */
if (matchMedia('(any-hover: hover)').matches)
{
	attivitaMenu.addEventListener('mouseenter', function (e) {
		cursorElement.classList.add('cursor--moon');
		customCursor.enterAttivita();
	});

	attivitaMenu.addEventListener('mouseleave', function (e) {
		cursorElement.classList.remove('cursor--moon');
		customCursor.leaveAttivita();
	});
}
