//================================= ENABLE/DISABLE CF7 CUSTOM SUBMIT BUTTON

$('.wpcf7-form').each(function () {
	let form = $(this);

	let wpcf7Acceptance = $('.wpcf7-acceptance:not(.optional) input', form);
	let wpcf7SubmitButton = $('.wpcf7-submit', form);

	function enableSubmitButton() {
		if (wpcf7Acceptance.length > 0)
		{
			let isPrivacyChecked = wpcf7Acceptance.prop('checked');

			wpcf7SubmitButton.prop('disabled', !isPrivacyChecked);
		}
	}

	enableSubmitButton();

	wpcf7Acceptance.on('change', function () {
		enableSubmitButton();
	});

});

/**
 * Autosize text inputs
 */

$('input.resizeable').each(function () {
	let input = $(this);

	input.attr('size', 4);
	input.wrap('<span class="resizeable-wrapper" data-value="' + input.attr('placeholder') + '"></span>');

	input.on('input', function () {
		let input = $(this);

		if (input.val().length > input.attr('placeholder').length)
		{
			input.parent().attr('data-value', input.val());
		}
		else
		{
			input.parent().attr('data-value', input.attr('placeholder'));
		}

	});
});


/**
 * Autosize textareas
 */

let textareas = document.querySelectorAll('textarea.autosize');

autosize(textareas);

/*
[...textareas].forEach(
	(item, pos) => {
		item.addEventListener('autosize:resized', function(){
			// do somenthing when a textarea changes size
		});
	}
);
*/


/**
 * Custom file inputs
 */
let customFileInputs = document.querySelectorAll( '.custom-file-input' );

Array.prototype.forEach.call(customFileInputs, function (input) {
	let label = document.querySelector('label[for=' + input.getAttribute('id') + ']'),
		labelVal = label.innerHTML;

	input.addEventListener('change', function (e) {
		let fileName = '';

		if (this.files && this.files.length > 1)
		{
			fileName = (label.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
		}
		else
		{
			fileName = e.target.value.split('\\').pop();
		}

		if (fileName)
		{
			label.querySelector('span').innerHTML = fileName;
		}
		else
		{
			label.innerHTML = labelVal;
		}
	});

	// Firefox bug fix
	input.addEventListener('focus', function () {
		input.classList.add('has-focus');
	});
	input.addEventListener('blur', function () {
		input.classList.remove('has-focus');
	});
});