//================================= SWIPER

let slideshowSwiper = new Swiper('.slideshow .swiper-container', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});


let clientiSwiper = new Swiper('.sezione-clienti .swiper-container', {
	slidesPerView: 'auto'
});


let teamSwiper = new Swiper('.agency-team-slideshow', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});


let moduleSlideshowSwiper = new Swiper('.module-slideshow .swiper-container', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});


let moduleSlideshowImmaginiVerticaliSwiper = new Swiper('.module-slideshow-immagini-verticali .swiper-container', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		slideChange: function(swiper) {
			let previousVideo = $(swiper.slides[swiper.previousIndex]).find('video');
			let currentVideo = $(swiper.slides[swiper.realIndex]).find('video');

			if (previousVideo.length > 0)
			{
				previousVideo[0].pause();
			}

			if (currentVideo.length > 0)
			{
				currentVideo[0].play();
			}
		}
	}
});


let moduleSlideshowWireframMobileSwiper = new Swiper('.module-slideshow-wireframe-mobile .swiper-container', {
	slidesPerView:  'auto',
	centeredSlides: true,
	spaceBetween: 24,
	breakpoints: {
		991.98: {
			spaceBetween: 60
		}
	},
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		slideChange: function(swiper) {
			let previousVideo = $(swiper.slides[swiper.previousIndex]).find('video');
			let currentVideo = $(swiper.slides[swiper.realIndex]).find('video');

			if (previousVideo.length > 0)
			{
				previousVideo[0].pause();
			}

			if (currentVideo.length > 0)
			{
				currentVideo[0].play();
			}
		}
	}
});


let altriProgettiSwiper = new Swiper('.altri-progetti .swiper-container', {
	slidesPerView:  'auto',
	centeredSlides: true,
	speed: 600,
	on: {
		init: function(swiper) {
			$('.altri-progetti-titles div:nth-child(1)').addClass('active');
		},
		slideChange: function(swiper) {
			$('.altri-progetti-titles div:nth-child(' + (swiper.realIndex + 1) + ')').addClass('active').siblings().removeClass('active');
		}
	}
});