const _window = $(window);
const body = $('body');
//import {goToByScroll} from './go-to-by-scroll';


/**
 * Safari browser detection
 * @type {boolean}
 */
const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (is_safari)
{
	body.addClass('is-safari');
}

/**
 * Change agency team slideshow background on hover
 */
$('.agency-team-slideshow')
	.on('mouseenter', function () {
		$('.agency-team-wrapper').addClass('agency-team-wrapper--hover');
	})
	.on('mouseleave', function () {
		$('.agency-team-wrapper').removeClass('agency-team-wrapper--hover');
	});


/**
 * Add diagonal decorations and animate them when they're in view
 */
let diagonal = $('.diagonal');

diagonal.each(function () {
	$(this).prepend('<div class="diagonal-line"><div></div></div>');
});

$(document).trigger('diagonalsReady');

function updateDiagonalStyle() {
	diagonal.each(function () {
		let item = $(this);

		item.imagesLoaded(function () {
			const addLength = item.attr('data-diag-add-length') * 1;
			const addAngle = item.attr('data-diag-add-angle') * 1;
			const w = item.outerWidth();
			const h = item.outerHeight();
			const diagonalAngle = Math.atan(w / h) * (180 / Math.PI);
			const diagonalLength = Math.sqrt((w * w) + (h * h));

			const finalLength = ((diagonalLength * 100) / h) + addLength; // calculate length in percentual and add addLength
			const finalAngle = diagonalAngle + addAngle;

			item.children('.diagonal-line').css({
				'transform': 'translate(-50%, -50%) rotate(' + finalAngle + 'deg)',
				'height':    finalLength + '%'
			});

			gsap.to(item.find('.diagonal-line > div')[0], {
				ease: "power1.out",
				duration: 1.0,
				startAt:  {
					height: 0
				},
				height: '100%',
				scrollTrigger: {
					trigger: item[0],
					start: "top 40%",
					//markers: true
				},
			});
		});
	});
}


/**
 * Autoplay video when it's in view
 */
const autoplayVideos = gsap.utils.toArray('.autoplay-on-scroll video')

autoplayVideos.forEach(function(video, i) {

	ScrollTrigger.matchMedia({

		"(orientation: landscape)": function() {

			let videoScrollTrigger = ScrollTrigger.create({
				trigger: video,
				start: 'top 60%',
				end: 'bottom 40%',
				//markers: true,
				onEnter: () => playVideo(video),
				onEnterBack: () => playVideo(video),
				onLeave: () => video.pause(),
				onLeaveBack: () => video.pause(),
			});

		},
		"(orientation: portrait)": function() {

			let videoScrollTrigger = ScrollTrigger.create({
				trigger: video,
				start: 'top 80%',
				end: 'bottom 20%',
				//markers: true,
				onEnter: () => playVideo(video),
				onEnterBack: () => playVideo(video),
				onLeave: () => video.pause(),
				onLeaveBack: () => video.pause(),
			});

		}

	});



	function playVideo(video) {
		if ($(video).parents('.swiper-slide').length === 0 || $(video).parents('.swiper-slide-active').length > 0)
		{
			video.play();
		}
	}

});


/**
 * Force ALL ScrollTrigger instances to re-calculate its start and end values after each image is loaded
 */
const siteContent = document.getElementById('content');
let images = siteContent.querySelectorAll('img');

Promise.all(Array.from(images).map(img => {

	if (img.complete)
	{
		return Promise.resolve(img.naturalHeight !== 0);
	}

	return new Promise(resolve => {

		img.addEventListener('load', () => {

			// refresh here
			ScrollTrigger.refresh();

			resolve(true);
		});

		img.addEventListener('error', () => resolve(false));

	});

})).then(results => {

	if (results.every(res => res))
	{
		//console.log('all images loaded successfully');
	}
	else
	{
		//console.log('some images failed to load, all finished loading');
	}

});



/**
 * Careers archive animation
 */
// This is just an example plugin that allows us to animate a "blur" property like gsap.to(target, {blur:10}) and it'll feed that value to this plugin which will do all the necessary calculations to add/update a blur() value in the CSS "filter" property (in browsers that support it). We wrap it in an iife just so that we can declare some local variables in a private scope at the top.
// reference: https://greensock.com/forums/topic/23666-simple-blur-and-fade-in-on-text/
(function() {
	const blurProperty = gsap.utils.checkPrefix("filter"),
		blurExp = /blur\((.+)?px\)/,
		getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

	gsap.registerPlugin({
		name: "blur",
		get(target) {
			return +(getBlurMatch(target)[1]) || 0;
		},
		init(target, endValue) {
			let data = this,
				filter = gsap.getProperty(target, blurProperty),
				endBlur = "blur(" + endValue + "px)",
				match = getBlurMatch(target)[0],
				index;
			if (filter === "none") {
				filter = "";
			}
			if (match) {
				index = filter.indexOf(match);
				endValue = filter.substring(0, index) + endBlur + filter.substring(index + match.length);
			} else {
				endValue = filter + endBlur;
				filter += filter ? " blur(0px)" : "blur(0px)";
			}
			data.target = target;
			data.interp = gsap.utils.interpolate(filter, endValue);
		},
		render(progress, data) {
			data.target.style[blurProperty] = data.interp(progress);
		}
	});
})();


if ($('.hero-careers__1').length > 0)
{
	ScrollTrigger.matchMedia({

		"(min-width: 768px)": function() {

			gsap.to(".hero-careers__1 span", {
				blur:          6,
				opacity:       0,
				y:             (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
				ease:          "none",
				scrollTrigger: {
					trigger: '.hero-careers__1',
					start:   "bottom bottom",
					end:     "bottom top",
					scrub:   true
				}
			});

		},
		"(max-width: 767.98px)": function() {

			// same as above but without blur
			gsap.to(".hero-careers__1 span", {
				opacity:       0,
				y:             (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
				ease:          "none",
				scrollTrigger: {
					trigger: '.hero-careers__1',
					start:   "bottom bottom",
					end:     "bottom top",
					scrub:   true
				}
			});

		}

	});

}


/**
 * Home page U animation
 */
if ($('#u-animation').length > 0)
{
	lottie.loadAnimation({
		container: document.getElementById('u-animation'),
		renderer:  'svg',
		loop:      true,
		autoplay:  true,
		path:      '/dsx/wp-content/themes/dsx/js/u-animation.json'
	});
}


$('.marquee__inner').append($('.marquee__inner').contents().clone()).append($('.marquee__inner').contents().clone());

$('.altri-progetti-titles > div').each(function (){
	let content = $(this).contents();

	$(this).append(content.clone()).append(content.clone()).append(content.clone()).append(content.clone());
});




/**
 * ON LOAD
 */

_window.on('load', function (event) {

	ScrollTrigger.refresh();

	updateDiagonalStyle();
});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

	updateDiagonalStyle();

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/