const _window = $(window);
const body = $('body');
import Cursor from './cursor';

export let cursorElement;
export let customCursor;


if (!body.hasClass('is-safari') && matchMedia('(any-pointer:fine)').matches)
{
	/**
	 * Initialize custom cursor
	 */
	cursorElement = document.querySelector('.cursor');
	customCursor = new Cursor(cursorElement);


	/**
	 * Show a bigger cursor when over a link
	 */
	[...document.querySelectorAll('a:not(.mag-button__text-inner), .link-all-item:not(.mag-button), .module-accordion__toggle')].forEach(
		(item, pos) => {

			item.addEventListener('mouseenter', function () {
				customCursor.enterActiveElement();
			});

			item.addEventListener('mouseleave', function () {
				customCursor.leaveActiveElement();
			});
		}
	);


	[...document.querySelectorAll('.swiper-container')].forEach(
		(item, pos) => {

			item.addEventListener('mouseenter', function () {
				cursorElement.classList.add('cursor--swipe');
				customCursor.enterSlideshow();
			});

			item.addEventListener('mouseleave', function () {
				cursorElement.classList.remove('cursor--swipe');
				customCursor.leaveSlideshow();
			});
		}
	);


	/**
	 * Change cursor color when over a colored background
	 */
	[...document.querySelectorAll('.altri-progetti')].forEach(
		(item, pos) => {

			item.addEventListener('mouseenter', function () {
				cursorElement.classList.add('cursor--white');
			});

			item.addEventListener('mouseleave', function () {
				cursorElement.classList.remove('cursor--white');
			});
		}
	);
}