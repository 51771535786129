const body = $('body');
import magButton from './mag-button';
import {cursorElement, customCursor} from './custom-cursor';


if (document.body.classList.contains('home'))
{
	let uTimer;
	const uDelay = 300;

	/**
	 * Show .sezione-intro animated background when over U svg
	 */
	/*const introHome = $('.sezione-intro', '.home');

	introHome.find('svg')
		.on('mouseenter', function () {

			clearTimeout(uTimer);

			uTimer = setTimeout(function () {

				introHome.addClass('sezione-intro--hover');
				body.addClass('inverted-header');

			}, uDelay);

		})
		.on('mouseleave', function () {
			clearTimeout(uTimer);

			uTimer = setTimeout(function () {

				introHome.removeClass('sezione-intro--hover');
				body.removeClass('inverted-header');

			}, uDelay);
		});*/


	/**
	 * Cycle background on loop in .sezione-intro__hover-bg
	 */
	/*function cycleBackgrounds() {
		let oldActiveImage;

		let activeImage = document.querySelector('.sezione-intro__hover-bg img.active');

		if (activeImage == null) // first cycle: show first image
		{
			activeImage = document.querySelector('.sezione-intro__hover-bg img');
		}
		else // after first cycle: show next image
		{
			oldActiveImage = activeImage;

			oldActiveImage.classList.add('old-active'); // set the last active image to be below the new active image

			activeImage.classList.remove('active');

			if (activeImage.nextElementSibling !== null) // show the next image if present
			{
				activeImage = activeImage.nextElementSibling;
			}
			else // else show again the first image
			{
				activeImage = document.querySelector('.sezione-intro__hover-bg img');
			}
		}

		// add active class to show image
		activeImage.classList.add('active');

		// remove old active image class and repeat the cycle
		setTimeout(function () {
			if (oldActiveImage != null)
			{
				oldActiveImage.classList.remove('old-active');
			}

			cycleBackgrounds();
		}, 2000);
	}

	cycleBackgrounds();*/


	if (matchMedia('(any-pointer:fine)').matches)
	{
		let sezioneAttivita = document.querySelector('.sezione-attivita')
		sezioneAttivita.addEventListener('mouseenter', cursorEnterHandler);
		sezioneAttivita.addEventListener('mouseleave', cursorLeaveHandler);

		[...document.querySelectorAll('.sezione-attivita .mag-button')].forEach(
			(item, pos) => {

				const magBtn = new magButton(item);

				magBtn.on('enter', () => customCursor.enterBtn());
				magBtn.on('leave', () => customCursor.leaveBtn());
			}
		);

		function cursorEnterHandler(e) {
			cursorElement.classList.add('cursor--moon');
			customCursor.enterAttivita();
		}

		function cursorLeaveHandler(e) {
			cursorElement.classList.remove('cursor--moon');
			customCursor.leaveAttivita();
		}
	}

}