import Menu from './menu';


if (document.body.classList.contains('post-type-archive-progetto'))
{
	let menu;

	enquire.register("screen and (min-width: 1440px)", {
		match:   function () {
			if (matchMedia('(any-pointer:fine)').matches)
			{
				$(document).on('diagonalsReady', function () {

					// menu (<nav> element)
					const menuEl = document.querySelector('.progetti-list.menu');

					// initialize menu
					menu = new Menu(menuEl);

				});
			}
		},
		unmatch: function () {
			menu.destroy();

			$('.progetto-item').each(function () {
				$('.progetto-item__attivita', $(this)).remove().appendTo($(this));
			});
		}
	});


	enquire.register("screen and (max-width: 1439.98px)", {
		match:   function () {
			$('.progetto-item').each(function () {

				let item = $(this);

				$('.progetto-item__attivita', item).remove().appendTo(item);

				let elementsToAnimate = item[0].querySelectorAll('.progetto-item__title, .progetto-item__attivita');

				gsap.to(elementsToAnimate, {
					opacity: 1,
					ease: "none",
					startAt:  {
						opacity: 0
					},
					scrollTrigger: {
						trigger: item[0],
						start: "top 50%", // the default values
						end: "top 100px",
						scrub: true,
						//markers: true
					},
				});

			});
		},
		unmatch: function () {
			$('.progetto-item').each(function () {
				$('.progetto-item__attivita', $(this)).remove().insertAfter($('.progetto-item__title', $(this)));
			});
		}
	});
}


