import {EventEmitter} from 'events';
import {lerp, getMousePos, calcWinsize, distance} from './utils';

// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

// Track the mouse position
let mousepos = {x: 0, y: 0};
window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

export default class magButton extends EventEmitter {

	constructor(el) {
		super();

		// DOM elements
		// el: main button
		// text: inner text element
		this.DOM = {el: el};
		this.DOM.text = this.DOM.el.querySelector('.mag-button__text');
		this.DOM.textinner = this.DOM.el.querySelector('.mag-button__text-inner');
		this.DOM.decoTop = this.DOM.el.querySelector('.mag-button__deco--1');

		// amounts the button will translate/scale
		this.renderedStyles = {
			tx:  {previous: 0, current: 0, amt: 0.1},
			ty:  {previous: 0, current: 0, amt: 0.1},
			tx2: {previous: 0, current: 0, amt: 0.05},
			ty2: {previous: 0, current: 0, amt: 0.05}
		};

		// button state (hover)
		this.state = {
			hover: false
		};

		// calculate size/position
		this.calculateSizePosition();

		// init events
		this.initEvents();

		// loop fn
		requestAnimationFrame(() => this.render());
	}


	calculateSizePosition() {
		// size/position
		this.rect = this.DOM.el.getBoundingClientRect();

		// the movement will take place when the distance from the mouse to the center of the button is lower than this value
		this.distanceToTrigger = this.rect.height * 1;

		// draw rectangle
		/*$('body').append('<div class="debug" style="border: 1px solid red; position: fixed; top:'+this.rect.top+'px; left:'+this.rect.left+'px; width:'+this.rect.width+'px; height:'+this.rect.height+'px; z-index:999999; pointer-events: none; "></div>');

		// draw center
		$('body').append('<div class="debug" style="border: 1px solid green; position: fixed; top:'+(this.rect.top + (this.rect.height /2))+'px; left:'+(this.rect.left + (this.rect.width /2))+'px; width:10px; height:10px; border-radius: 50%; transform: translate(-50%, -50%); z-index:999999; pointer-events: none; "></div>');*/

		// draw magnetic circle limit
		/*$('body').append('<div class="debug" style="border: 1px solid orange; position: fixed; top:'+(this.rect.top + (this.rect.height /2))+'px; left:'+(this.rect.left + (this.rect.width /2))+'px; width:'+this.distanceToTrigger*2+'px; height:'+this.distanceToTrigger*2+'px; border-radius: 50%; transform: translate(-50%, -50%); z-index:999999; pointer-events: none; opacity: 0.5; "></div>');*/
	}


	initEvents() {
		this.onResize = () => this.calculateSizePosition();

		// calculate new positions on resize and on scroll
		window.addEventListener('resize', this.onResize);
		window.addEventListener('scroll', this.onResize);
	}


	render() {

		// calculate the distance from the mouse to the center of the button
		const distanceMouseButton = distance(mousepos.x + window.scrollX, mousepos.y + window.scrollY, this.rect.left + window.scrollX + this.rect.width / 2, this.rect.top + window.scrollY + this.rect.height / 2);

		// new values for the translations and scale
		let x = 0;
		let y = 0;

		if (distanceMouseButton < this.distanceToTrigger)
		{
			if (!this.state.hover)
			{
				this.enter();
			}
			x = (mousepos.x + window.scrollX - (this.rect.left + window.scrollX + this.rect.width / 2)) * .3;
			y = (mousepos.y + window.scrollY - (this.rect.top + window.scrollY + this.rect.height / 2)) * .3;
		}
		else if (this.state.hover)
		{
			this.leave();
		}

		this.renderedStyles['tx'].current = this.renderedStyles['tx2'].current = x;
		this.renderedStyles['ty'].current = this.renderedStyles['ty2'].current = y;

		for (const key in this.renderedStyles)
		{
			this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
		}

		this.DOM.decoTop.style.transform = `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0)`;
		this.DOM.text.style.transform = `translate3d(${this.renderedStyles['tx'].previous * 0.5}px, ${this.renderedStyles['ty'].previous * 0.5}px, 0)`;

		requestAnimationFrame(() => this.render());
	}


	enter() {
		this.emit('enter');
		this.state.hover = true;

		this.DOM.el.classList.add('mag-button--hover');

		gsap.killTweensOf(this.DOM.textinner);

		gsap
			.timeline()
			.to(this.DOM.textinner, 0.1, {
				ease:    'power3.out',
				opacity: 0,
				y:       '-10%'
			}, 0)
			.to(this.DOM.textinner, 0.2, {
				ease:    'expo.out',
				opacity: 1,
				startAt: {y: '20%'},
				y:       '0%'
			});
	}


	leave() {
		this.emit('leave');
		this.state.hover = false;

		this.DOM.el.classList.remove('mag-button--hover');

		gsap.killTweensOf(this.DOM.textinner);

		gsap
			.timeline()
			.to(this.DOM.textinner, 0.1, {
				ease:    'power3.out',
				opacity: 0,
				y:       '10%'
			}, 0)
			.to(this.DOM.textinner, 0.2, {
				ease:    'expo.out',
				opacity: 1,
				startAt: {y: '-20%'},
				y:       '0%'
			});
	}

}
