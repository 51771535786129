const _window = $(window);
const body = $('body');


const formContainer = $('.form-container');
const formStepsContainer = $('.form-steps-container');


/**
 * Open form on click and reload page on second click
 */

$('.form-container__toggle').on('click', function () {

	if (formContainer.hasClass('form-container--open'))
	{
		location.reload();
	}
	else
	{
		formContainer.addClass('form-container--open');
		body.addClass('contatti-open');
	}
});


/**
 * Open form on scroll down
 */
window.addEventListener('wheel', function (event) {
	if (event.deltaY > 0)
	{
		if (!formContainer.hasClass('form-container--open'))
		{
			$('.form-container__toggle').trigger('click');
		}
	}
});


/**
 * Open form on swipe up
 */
/*document.addEventListener('swiped-up', function(e) {
	if (!formContainer.hasClass('form-container--open'))
	{
		$('.form-container__toggle').trigger('click');
	}
});*/


/**
 * Close form on click
 */

$('.form-container__close').on('click', function () {
	formContainer.removeClass('form-container--open form-container--sent');
	body.removeClass('contatti-open');
});


/**
 * Form validation
 */

const $sections = $('.form-step');

// Return the current index by looking at which section has the class 'form-step--active'
function curIndex() {
	return $('.form-step--active').index();
}

// Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
$sections.each(function (index, section) {
	$(section).find(':input').attr('data-parsley-group', 'block-' + index);
});


// Validate inputs for each step
$('.btn--forward', formStepsContainer).on('click', function () {
	const input = $(this);

	$('.wpcf7-form').parsley({
		//errorClass: 'wpcf7-not-valid',
		//focus:                  'none',
		errorsMessagesDisabled: true
	})
		.on('form:error', function () {
			// disable forward button
			//formStep.find('.btn--forward').attr('disabled', 'disabled');
		})
		.on('field:success', function () {
			let formStep = formStepsContainer.find('.form-step--active');

			if ($('.parsley-error', formStep).length === 0)
			{
				$('.fields-error', formStep).remove();
			}
		})
		.on('field:error', function () {
			let formStep = formStepsContainer.find('.form-step--active');

			if ($('.fields-error', formStep).length === 0)
			{
				$('.btn', formStep).before('<span class="fields-error">Compila tutti i campi</span>');
			}
		})
		.whenValidate({
			group: 'block-' + curIndex()
		})
		.done(function () {
			// advance to the next step
			let formStep = formStepsContainer.find('.form-step--active');

			formStep.removeClass('form-step--active').addClass('form-step--out').next().addClass('form-step--active');

			setTimeout(function () {
				formStep.removeClass('form-step--out');
			}, 600);

			setTimeout(function () {
				formContainer.animate({scrollTop: 0}, 600);
			}, 300);
		});
});


// Things to do when submitting
$('.form-steps-container__submit', formStepsContainer).on('click', function () {

	// copy values in response placeholders
	$('.response-nome').text($('input[name="nome-cognome"]').val());
	$('.response-tipologia').text($('select[name="tipologia"]').val());
	$('.response-email').text($('input[name="email"]').val());
	$('.response-telefono').text($('input[name="telefono"]').val());
});


// Hide configuratore steps and show success message on CF7 callback
// available events: wpcf7invalid wpcf7mailsent wpcf7mailfailed
$('.wpcf7', '.page-template-page-contatti-old').on('wpcf7mailsent', function (event) {

	setTimeout(function () {
		formContainer.animate({scrollTop: 0}, 600);
	}, 300);

	$('.form-steps-container').hide();
	body.addClass('contatti-sent');
	formContainer.addClass('form-container--sent');
});


$('.wpcf7', '.page-template-page-contatti').on('wpcf7mailsent', function (event) {
	$('.fields-error', $(this)).hide();

	// copy values in response placeholders
	$('.response-nome').text($('input[name="nome-cognome"]').val());
	$('.response-tipologia').text($('select[name="tipologia"]').val());
	$('.response-email').text($('input[name="email"]').val());
	$('.response-telefono').text($('input[name="telefono"]').val());

	body.addClass('contatti-sent');
});

$('.wpcf7', '.page-template-page-contatti').on('wpcf7invalid', function (event) {
	$('.fields-error', $(this)).show();
});


$('.wpcf7', '.widget-form').on('wpcf7mailsent', function (event) {
	$('.fields-error', $(this)).hide();

	// copy values in response placeholders
	$('.response-nome').text($('input[name="nome-cognome"]', $(this)).val());
	$('.response-tipologia').text($('select[name="tipologia"]', $(this)).val());
	$('.response-email').text($('input[name="email"]', $(this)).val());
	$('.response-telefono').text($('input[name="telefono"]', $(this)).val());

	body.addClass('contatti-sent');
});

$('.wpcf7', '.widget-form').on('wpcf7invalid', function (event) {
	$('.fields-error', $(this)).show();
});


// empty the first value of the "tipologia" select to trigger validation
$('select[name=tipologia] option:first', formStepsContainer).val('');


// open "dettagli" textarea after selecting a value in "tipologia" select
$('select[name=tipologia]', formStepsContainer).on('change', function () {
	if ($(this).val() !== '')
	{
		$(this).addClass('parsley-success');
		$('.dettagli-wrapper').slideDown();
	}
	else
	{
		$(this).removeClass('parsley-success');
		$('.dettagli-wrapper').slideUp();
	}
});

$('select[name=tipologia]', '.page-template-page-contatti').on('change', function () {
	if ($(this).val() !== '')
	{
		$(this).addClass('parsley-success');
	}
	else
	{
		$(this).removeClass('parsley-success');
	}
});

$('select[name=tipologia]', '.widget-form').on('change', function () {
	if ($(this).val() !== '')
	{
		$(this).addClass('parsley-success');
	}
	else
	{
		$(this).removeClass('parsley-success');
	}
});


// reload page when clicking on .response-back button
$('.response-back', '.page-template-page-contatti-old').on('click', function () {
	location.reload();
});

$('.response-back').on('click', function () {
	$('.toggle-control--active').removeClass('toggle-control--active');
	$('.parsley-success').removeClass('parsley-success');
	$('.wpcf7-submit').attr('disabled', 'disabled');

	body.removeClass('contatti-sent');
});